// -----------------------------------------------------------------------------------------------------
// @ snackbars
// -----------------------------------------------------------------------------------------------------
@import "variables";
.error-snackbar {
  color: $snackbar-error-color !important;
  background-color: $snackbar-error-background-color !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;
  border: 1px solid $snackbar-error-border-color;
  padding: 12px 5px 10px 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mat-snack-bar-container {
    color: $snackbar-error-color !important;
    background-color: $snackbar-error-background-color !important;
    margin-top: 7px !important;
  }
  .divButtonError,
  .mat-button {
    background-color: $snackbar-error-background-color !important;
    color: $snackbar-error-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }
  .mat-simple-snackbar {
    display: flex !important;
    justify-content: center !important;
  }
  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .divButtonSuccess {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-error-icon-color!important;
  }

  .custmized-snackbar-close .mat-icon {
    color: #000!important;
  }
}

.success-message-snackbar {
  color: $snackbar-success-color !important;
  background-color: $snackbar-success-background-color !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;
  border: 1px solid $snackbar-success-border-color;
  padding: 12px 5px 10px 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mat-snack-bar-container {
    margin-top: 7px !important;
    color: $snackbar-success-color !important;
    background-color: $snackbar-success-background-color !important;
  }
  .divButtonSuccess,
  .mat-button {
    background-color: $snackbar-success-background-color !important;
    color: $snackbar-success-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }
  .mat-simple-snackbar {
    display: flex !important;
    justify-content: space-between !important;
    span {
      text-align: center;
      width: 100%;
    }
  }
  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-success-icon-color!important;
  }
  .custmized-snackbar-close .mat-icon {
    color: #000!important;
  }
}

.info-message-snackbar {
  color: $snackbar-info-color !important;
  background-color: $snackbar-info-background-color !important;
  max-width: 100vw !important;
  min-width: 0 !important;
  margin-top: 7px !important;
  border: 1px solid $snackbar-info-border-color;
  padding: 12px 5px 10px 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mat-snack-bar-container {
    color: $snackbar-info-color !important;
    background-color: $snackbar-info-background-color !important;
    margin-top: 7px !important;
  }
  .divButtonSuccess,
  .mat-button {
    background-color: $snackbar-info-background-color !important;
    color: $snackbar-info-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }
  .mat-simple-snackbar {
    display: flex !important;
    justify-content: center !important;
  }
  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-info-icon-color!important;
  }
}
